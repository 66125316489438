<template>
  <a-layout id="components-layout-demo-top-side-2" style=" overflow-x:auto;">
    <global-header style="position: fixed;width: 100%;z-index: 9;"></global-header>
    <a-layout>
      <a-layout style="padding: 70px 0px 24px 0px;overflow:auto">
        <!-- <a-breadcrumb style="margin: 16px 0">
          <a-breadcrumb-item>Home</a-breadcrumb-item>
          <a-breadcrumb-item>List</a-breadcrumb-item>
          <a-breadcrumb-item>App</a-breadcrumb-item>
        </a-breadcrumb> -->
        <a-layout-content
          :style="{ background: '#fbfcff', margin: 0, minHeight: 'calc(100vh - ' + 100 + 'px)' }"
        >
          <!-- Content -->
          <router-view />

        </a-layout-content>
        <global-footer />
      </a-layout>
    </a-layout>
  </a-layout>
</template>
<script>
import GlobalHeader from '@/components/GlobalHeader'
import GlobalFooter from '@/components/GlobalFooter'
export default {
    name: 'BasicLayout',
    components: {
        GlobalFooter,
        GlobalHeader,
    },
    data() {
        return {
        collapsed: false,
        };
    },
};
</script>

<style lang="less" scoped>
  .ant-layout{
    background: #fbfcff;
  }
</style>