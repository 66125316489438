<template>
  <a-layout id="components-layout-demo-top-side-2">
    <global-header style="position: fixed;width: 100%;z-index: 9;"></global-header>
    
    <a-layout>
      <global-sider style="position: fixed;top:70px;height:calc( 100vh - 70px) ">
        <template v-slot:menuRender>
            <sider-menu ></sider-menu>
        </template>
      </global-sider>
      <a-layout style="padding: 90px 24px 24px 220px">
        <!-- <a-breadcrumb style="margin: 16px 0">
          <a-breadcrumb-item>Home</a-breadcrumb-item>
          <a-breadcrumb-item>List</a-breadcrumb-item>
          <a-breadcrumb-item>App</a-breadcrumb-item>
        </a-breadcrumb> -->
        <a-layout-content
          :style="{ background: '#fff', padding: '24px', margin: 0, minHeight: 'calc(100vh - ' + 120 + 'px)' ,    minWidth: '1280px'}"
        >
          <!-- Content -->
          <router-view />
          
        </a-layout-content>
   
      </a-layout>
    </a-layout>
  </a-layout>
</template>
<script>
import GlobalHeader from '@/components/GlobalHeader'
import GlobalSider from '@/components/GlobalSider'
import SiderMenu from '@/components/SiderMenu'
export default {
    name: 'BasicLayout',
    components: {
        GlobalSider,
        GlobalHeader,
        SiderMenu
    },
    data() {
        return {
        collapsed: false,
        };
    },
};
</script>

<style lang="less" scoped>



</style>