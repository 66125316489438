import request from '@/utils/request'

const api = {
  showtpl: '/api/showtpl',
  tplflag:'/api/tplflag',
  xeuorder:'/api/auth/getxeuorder',
  xeuworks:'/api/auth/showxeuwork',
  xeupointslog:'/api/auth/showpointslog',
  smlist:'/api/auth/showsmlist',
  couponlist:'/api/auth/showcoupon',
  favlist:'/api/auth/showfav',
  sellcouponlist:'/api/auth/showsellcouponlist',
  userlevelrule:'/api/auth/getuserlevelrule'

}

export default api

export function getShowtpl(parameter) {
    return request({
        url: api.showtpl,
        method: 'get',
        params: parameter
    })
}

export function getTplflag() {
    return request({
        url: api.tplflag,
        method: 'get',

    })
}

export function getXeuorder (parameter) {
  return request({
    url: api.xeuorder,
    method: 'get',
    params: parameter
    
  })
}

export function getXeuworks (parameter) {
  return request({
    url: api.xeuworks,
    method: 'get',
    params: parameter
    
  })
}

export function getXeupointslog (parameter) {
  return request({
    url: api.xeupointslog,
    method: 'get',
    params: parameter
    
  })
}

export function getSmlist (parameter) {
  return request({
    url: api.smlist,
    method: 'get',
    params: parameter
    
  })
}

export function getCouponlist (parameter) {
  return request({
    url: api.couponlist,
    method: 'get',
    params: parameter
    
  })
}

export function getFavlist (parameter) {
  return request({
    url: api.favlist,
    method: 'get',
    params: parameter
    
  })
}

export function getSellCouponlist (parameter) {
  return request({
    url: api.sellcouponlist,
    method: 'get',
    params: parameter
    
  })
}

export function getUserlevelrule (parameter) {
  return request({
    url: api.userlevelrule,
    method: 'get',
    params: parameter
    
  })
}