<template>
<div class="modal">
     <div class="modal-header">
                <h2>登录</h2>
            </div>
<div class="modal-box">
         <div class="modal-body">
          <div class="login-text">
               <a-icon type="wechat" class="icon"/>
               <span>微信扫码登录</span>
          </div>
          <div class="erweima">
              <div class="kuang">
                  <span></span>
                  <span></span>
                  <span></span>
                  <span></span>
                   <!-- <img src="@/assets/xe-erweima.png" alt=""> -->
                   <wx-login :appid="appid" :scope="scope" :redirect_uri="redirect_uri" :href='href' ></wx-login>
              </div>
          </div>
         </div>
         <div class="modal-foot">
            <p>首次登录会直接注册XE账号</p>
            <p> 登录即同意 <span>用户协议</span> 和 <span>隐私协议</span> </p>
         </div>
        </div>
</div>

</template>

<script>
import wxLogin from "@/components/wxLogin";
export default {
    data(){
        return{
            appid : 'wx115a4979f2c8f081',
            scope : 'snsapi_login',
            redirect_uri : '',
            href:"data:text/css;base64,LmltcG93ZXJCb3ggLnFyY29kZXt3aWR0aDoxODVweH0NCi5pbXBvd2VyQm94IC5xcmNvZGV7bWFyZ2luLXRvcDowfQ0KLmltcG93ZXJCb3ggLndycF9jb2Rle3dpZHRoOjE4NXB4fQ0KLmltcG93ZXJCb3ggLmluZm97bWFyZ2luOjA7d2lkdGg6MjAwcHh9DQouaW1wb3dlckJveCAudGl0bGV7ZGlzcGxheTpub25lfQ0KLmltcG93ZXJCb3ggLmljb24zOF9tc2d7ZGlzcGxheTpub25lfQ0KLmltcG93ZXJCb3ggLnN0YXR1c190eHR7dGV4dC1hbGlnbjogY2VudGVyO30NCg=="
        }
    },
    components: {
      wxLogin,
      
  },
    mounted(){
         this.redirect_uri=window.location.href
    },
    methods:{

    }
}
</script>

<style lang="less" scoped>
   .modal-header{
        padding: 8px 15px;
        h2{
            font-size: 24px;
            font-weight: 700;
        }
    }
.modal-box{
    width: 350px;
    height: 400px;
    background-color: #fff;
    border-radius: 8px;
    margin:0px auto;
    padding: 20px 0;
    .modal-body{
        margin-top: 20px;
        margin: 0 auto;
        .erweima{
            margin-top: 25px;
            text-align: center;
            width: 100%;
            .kuang{
                position: relative;
                border: 1px solid #e6e6e6;
                width: 188px;
                height: 188px;
                margin: 0 auto;
                img{
                    margin: 6px 0;
                }
            }
            .kuang span:nth-child(1){
     position : absolute ;
     left : -3px ;
     top : -3px ;
     padding : 12px ;
     border-style : solid ;
     border-color : #9e9e9e;
     border-width : 3px 0 0 3px ;
}
.kuang span:nth-child(2){
     position : absolute ;
     right : -3px ;
     top : -3px ;
     padding : 12px ;
     border-style : solid ;
     border-color : #9e9e9e;
     border-width : 3px 3px 0 0 ;
}
.kuang span:nth-child(3){
     position : absolute ;
     right : -3px ;
     bottom : -3px ;
     padding : 12px ;
     border-style : solid ;
     border-color : #9e9e9e;
     border-width : 0 3px 3px 0 ;
}
.kuang span:nth-child(4){
     position : absolute ;
     left : -3px ;
     bottom : -3px ;
     padding : 12px ;
     border-style : solid ;
     border-color : #9e9e9e;
     border-width : 0 0 3px 3px ;
}
        }
      .login-text{
          text-align: center;
        span{
            font-size: 18px;
            font-weight: 700;
            color: #333;
        }
        .icon{
            font-size: 30px;
            color: #23c82e;
            margin-right: 8px;
        }
      }
    }
    .modal-foot{
        text-align: center;
        margin-top: 58px;
        p{
            margin-bottom: 0px;
            color: #a5aec5;
            span{
                color: #333;
            }
        }
    }
}
</style>