import Vue from 'vue'
import Antd from 'ant-design-vue';
import App from './App.vue'
import router from './router'
import store from './store/'
import Dialog from '@/components/Dialog'
import waterfall from 'vue-waterfall2'
Vue.use(waterfall)
Vue.config.ignoredElements = ['wx-open-launch-weapp'];
import bootstrap from './core/bootstrap'
import './permission' // permission control

import 'ant-design-vue/dist/antd.css';
import './assets/iconfont/iconfont-weapp-icon.css'
import './assets/iconfont/iconfont.css'

Vue.config.productionTip = false
Vue.use(Antd);
Vue.use(Dialog) // this.$dialog func
new Vue({
  router,
  store,
  created: bootstrap,
  render: h => h(App),
}).$mount('#app')
