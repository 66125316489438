import store from '@/store'
import storage from 'store'
import {
    ACCESS_TOKEN,
    REFRESH_TIME
} from '@/store/mutation-types'
import { checkrefreshtoken } from '@/api/base.js'

export default function Initializer() {
    store.commit('SET_TOKEN', storage.get(ACCESS_TOKEN))
    store
        .dispatch('GetBase')
        .then(res => {
            console.log(res)
        })
    store.commit('SET_TOKEN', storage.get(ACCESS_TOKEN))
    store
        .dispatch('GetTplFlag')
        .then(res => {
            console.log(res)
        })
        // last step
    const time = setInterval(function() {
        if (storage.get(ACCESS_TOKEN) != undefined) {
            if (Math.round(new Date().getTime() / 1000).toString() > storage.get(REFRESH_TIME) + 3600)
                checkrefreshtoken().then((res) => {
                    if (res.code === 200) {
                        storage.set(ACCESS_TOKEN, res.data.token, 7 * 24 * 60 * 60 * 1000)
                        storage.set(REFRESH_TIME, res.data.refreshtime, 7 * 24 * 60 * 60 * 1000)
                        console.log(res);
                    } else if (res.code === 401) {
                        clearInterval(time)
                    }
                })
        }
    }, 600000)
}