<template>
  <a-layout-footer class="footer">
    <div class="footer-img">
      <h2>合作伙伴</h2>
      <div class="img">
        <img src="@/assets/img/tencent_logo.png" alt="">
         <img src="@/assets/img/TB.png" alt="">
          <img src="@/assets/img/移动.png" alt="">
           <img src="@/assets/img/dianxin.png" alt="">
      </div>
    </div>
    <div class="footer-wrap">
        <a-row>
          <a-col :span="3" v-for="(item,index) in footdata" :key="index" class="foot-nav">
            <h2>{{footdata[index].footerNav}}</h2>
           <div v-for="(item,index) in footdata[index].childfooterNav" :key="index" class="foot-item">
              <a :href="item.src"  target="_blank" v-if="item.src !='null'">
              {{item.name}}
            </a>
            <p v-else class="code-p">{{item.name}}
              <span class="code-box" v-if="item.text1!='null'">
                <span class="code-small">
                <img src="@/assets/img/OfficialAccountsCode.jpg" alt="" v-if="item.code === 'offical'">
                <img src="@/assets/img/CustomerService.jpg" alt="" v-else style="margin-bottom:5px;">
                <p>{{item.text1}}</p>
                 <p>{{item.text2}}</p>
              </span>
              </span>
              <span v-else class="business-box">
              <span class="business">
                  <p>商务QQ号:</p>
                <p>3198712623</p>
              </span>
              </span>
            </p>
           </div>
          </a-col>
           <a-col :span="3" v-for="(citem,cindex) in Contactus" :key="cindex+100" class="foot-nav">
            <h2>{{citem.footerNav}}</h2>
            <p  v-for="(item,index) in citem.childfooterNav" :key="index">
              {{item}}
            </p>
          </a-col>
          <a-col :span="6" class="foot-logo-code">
           <div class="logo-code">
              <img src="@/assets/logo.svg" alt="" class="logo">
               <img src="@/assets/erweima.png" alt="" class="code">
               <p>扫码立即使用设计工具</p>
           </div>
          </a-col>
        </a-row>
    </div>
    <p class="Internetcontentprovider"><a href="https://beian.miit.gov.cn/">粤ICP 备2020105455号-1 </a>| Copyright(C)2020 汕头市轩逸科技有限公司</p>
  </a-layout-footer>
</template>

<script>
import data from '@/assets/data.json'
export default {
  name: 'GlobalFooter',
  data(){
    return{
   footdata:data.footer,
   Contactus:data.Contactus
    }
  }
}
</script>

<style lang="less" scoped>
.footer{
  background: none;
  .Internetcontentprovider{
    margin-top: 32px;
    color: #a5aec5;
  }
}
.footer-img{
   border-top: 1px solid #ccc;
  padding: 30px 0;
   h2{
    font-size: 24px;
    font-weight: 700;
    color: #333;
    margin-bottom: 20px;
  }
    .img{
    img{
      margin-right: 18px;
    }
  }
}
.footer-wrap{
  border-top: 1px solid #ccc;
    border-bottom: 1px solid #ccc;
  padding: 50px 0;

  .code-p{
    cursor: pointer;
    position: relative;
     .business-box{
  position: absolute;
      display: none;
      top: -30px;
      left: -125px;
    }
    .business{
    display: block;
      background-color: #fff;
      z-index: 8;
      padding: 10px 20px;
        cursor: text;
         box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.2);
      p{
        margin-bottom: 0;
            color: #333;

      }
    }
    .code-box{
      display: none;
     position: absolute;
      top: 0;
      left: 60px;
       z-index: 6;
       box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.3);
    }
    .code-small{
      display: block;
      width: 160px;
      background-color: #fff;
      text-align: center;
      padding: 10px 0;
      img{
        width: 127px;
      }
      p{
        margin-bottom: 3px;
        color: #333;
      }
    }
  }
  .code-p:hover{
    .code-box{
      display: block;
    }
     .business-box{
 display: block;
    }
  }
  .foot-logo-code{
    display: flex;
    justify-content: flex-end;
    .logo{
      width: 127px;
   margin-bottom: 25px;
   display: block;
  }
  .code{
    width: 127px;
  }
  p{
    margin-top: 18px;
    color: #333;
  }
  }
  h2{
    font-size: 24px;
    font-weight: 700;
    color: #333;
  }
  a{
    color: #a5aec5;
    display: block;
    margin-bottom: 10px;
  }
  p{
     color: #a5aec5;
    display: block;
    margin-bottom: 10px;
  }
  .foot-nav .foot-item:last-child a{
    margin-bottom: 0;
  }
}
</style>
