<template>
  <div :class="wrpCls">
    <div class="search" v-if="isSearch">
      <a-input class="search_input" @pressEnter="search"/>
      <div class="search_text">
        <a-icon type="search" />
        搜素
      </div>
    </div>
    <div v-if="nickname!=''" >
      <div class="more" @click="handleTowork">
        <button class="more_btn">我的工作台</button>
        <div class="more_div"></div>
      </div>
      <avatar-dropdown
        :menu="showMenu"
        :current-user="currentUser"
        :class="prefixCls"
      />
    </div>
    <div v-else>
      <div class="more" @click="login">
        <button class="more_btn">登录</button>
        <div class="more_div"></div>
      </div> 
    </div>
  </div>
</template>

<script>
import AvatarDropdown from "./AvatarDropdown";
import LoginModal from "@/components/LoginModal";
import { mapGetters } from 'vuex'
export default {
  name: "RightContent",
  components: {
    AvatarDropdown,
  },
  props: {
    prefixCls: {
      type: String,
      default: "ant-pro-global-header-index-action",
    },
    isMobile: {
      type: Boolean,
      default: () => false,
    },
    topMenu: {
      type: Boolean,
      required: true,
    },
    theme: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      showMenu: true,
      currentUser: {},
      loginStatus: true,
      isSearch: false,
    };
  },
  computed: {
    ...mapGetters(['nickname', 'avatar','xeupoints','userGrade','member']),
    wrpCls() {
      return {
        "ant-pro-global-header-index-right": true,
        [`ant-pro-global-header-index-${
          this.isMobile || !this.topMenu ? "light" : this.theme
        }`]: true,
      };
    },
  },
  mounted() {
    setTimeout(() => {
      this.currentUser = {
        name: this.nickname,
        avatar:this.avatar,
        xeupoints:this.xeupoints,
        userGrade:this.userGrade,
        xeuCode:this.member.xeuCode
      };
    }, 1500);
  },
  methods: {
    search(e){
        //从路由最后新增参数
        let  currentRoute = JSON.parse(JSON.stringify(this.$router.currentRoute.query)) // 这里我们需要的应该是值，因此必须转为深拷贝
         Object.assign(currentRoute, {keywords:e.target.value})
        this.$router.push({path: '/store/search',query:currentRoute})
    },
    handleTowork() {
      this.$router.push({ path: "/manage/works" });
    },
    login() {
      let _this = this;
      _this.$dialog(
        LoginModal,
        // component props
        {
          record: {},
          on: {
            close() {
              console.log("modal close 回调");
            },
          },
        },
        // modal props
        {
          width: 360,
          centered: true,
          maskClosable: false,
          footer: "",
          bodyStyle: { padding: "0px", position: "relative" },
        }
      );
    },
  },
  watch: {
    $route: {
      handler: function (val) {
        console.log(val.name);
        if (val.name === "search") {
          this.isSearch = true;
        } else {
          this.isSearch = false;
        }
      },
      deep: true,
      immediate: true,
    },
  },
};
</script>

<style scoped>
.ant-pro-global-header-index-right {
  float: right;
}
.search {
  background-color: #f6f7fb;
  width: 320px;
  height: 40px;
  border-radius: 20px;
  position: absolute;
  top: 15px;
  right: 220px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 0 0 20px;
}
.search .search_input {
  border: none;
  background-color: #f6f7fb;
  height: 40px;
}
.search .search_input:focus {
  box-shadow: none;
}
.search_text {
  background-color: #0081ff;
  width: 80px;
  flex-shrink: 0;
  height: 100%;
  text-align: center;
  color: #fff;
  line-height: 40px;
  border-radius: 0 19px 19px 0;
  cursor: pointer;
}
.more {
  float: left;
  position: relative;
  top: 10px;
  cursor: pointer;
  width: 100px;
  height: 35px;
  box-shadow: 3px 8px 4px rgba(0, 0, 0, 0.33);
  border-radius: 20px;
  margin-right: 15px;
}
.more_div {
  cursor: pointer;
  position: absolute;
  top: 7px;
  left: 0;
  width: 100px;
  height: 35px;
  background-image: linear-gradient(to right, #085cf5, #27bbee);
  border-radius: 20px;
  border: 1px solid #0d5097;
  box-shadow: inset 1px 3px 20px #002372;
  z-index: 2;
}
.more_btn {
  cursor: pointer;
  position: absolute;
  top: 0;
  left: 0;
  width: 100px;
  height: 35px;
  background-image: linear-gradient(to right, #0052ff, #27bbee);
  border: 1px solid #00e0fe;
  border-radius: 20px;
  color: #fff;
  z-index: 3;
  line-height: 35px;
}
</style>
