import storage from 'store'
import { login, getInfo, logout } from '@/api/login'
import { ACCESS_TOKEN, REFRESH_TIME } from '@/store/mutation-types'
import { welcome } from '@/utils/util'

const user = {
    state: {
        token: '',
        name: '',
        welcome: '',
        avatar: '',
        xeupoints: '',
        xeutotalpoints: '',
        userGrade: '',
        couponAmount: '',
        member: {},
        roles: [],
        info: {}
    },

    mutations: {
        SET_TOKEN: (state, token) => {
            state.token = token
        },
        SET_NAME: (state, { name, welcome }) => {
            state.name = name
            state.welcome = welcome
        },
        SET_AVATAR: (state, avatar) => {
            state.avatar = avatar
        },
        SET_XEUPOINTS: (state, xeupoints) => {
            state.xeupoints = xeupoints
        },
        SET_XEUTOTALPOINTS: (state, xeutotalpoints) => {
            state.xeutotalpoints = xeutotalpoints
        },
        SET_USERGRADE: (state, userGrade) => {
            state.userGrade = userGrade
        },
        SET_MEMBER: (state, member) => {
            state.member = member
        },
        SET_ROLES: (state, xeupoints) => {
            state.xeupoints = xeupoints
        },
        SET_INFO: (state, info) => {
            state.info = info
        },
        SET_COUPONAMOUNT: (state, couponAmount) => {
            state.couponAmount = couponAmount
        },


    },

    actions: {
        // 登录
        Login({ commit }, userInfo) {
            return new Promise((resolve, reject) => {
                login(userInfo).then(response => {
                    console.log(response)
                    if (response.code === 200) {
                        const result = response.data
                        storage.set(ACCESS_TOKEN, result.token, 7 * 24 * 60 * 60 * 1000)
                        storage.set(REFRESH_TIME, Math.round(new Date().getTime() / 1000).toString(), 7 * 24 * 60 * 60 * 1000)
                        commit('SET_TOKEN', result.token)
                        resolve()
                    }

                }).catch(error => {
                    reject(error)
                })
            })
        },

        // 获取用户信息
        GetInfo({ commit }) {
            return new Promise((resolve, reject) => {
                getInfo().then(response => {
                    const member = {}
                    const result = response.data
                    console.log(result)
                        // if (result.role && result.role.permissions.length > 0) {
                        //   const role = result.role
                        //   role.permissions = result.role.permissions
                        //   role.permissions.map(per => {
                        //     if (per.actionEntitySet != null && per.actionEntitySet.length > 0) {
                        //       const action = per.actionEntitySet.map(action => { return action.action })
                        //       per.actionList = action
                        //     }
                        //   })
                        //   role.permissionList = role.permissions.map(permission => { return permission.permissionId })
                        //   commit('SET_ROLES', result.role)
                        //   commit('SET_INFO', result)
                        // } else {
                        //   reject(new Error('getInfo: roles must be a non-null array !'))
                        // }

                    commit('SET_NAME', { name: result.xeunickname, welcome: welcome() })
                    commit('SET_AVATAR', result.xeuavatar)
                    commit('SET_XEUPOINTS', result.xeupoints)
                    commit('SET_XEUTOTALPOINTS', result.xeutotalpoints)
                    commit('SET_USERGRADE', result.xeulvname)
                    commit('SET_COUPONAMOUNT', result.couponAmount)
                        // 计算会员是否到期
                        // member.memberState=false
                    member.meberGrade = '月会员'
                    member.meberEndTime = result.xeumemendtime
                    member.Discount = result.Discount
                    member.xeuCode = result.xeucode
                    member.memberState = new Date(result.xeumemendtime).getTime() > new Date().getTime() ? true : false
                    commit('SET_MEMBER', member)

                    resolve(response)
                }).catch(error => {
                    reject(error)
                })
            })
        },

        // 登出
        Logout({ commit, state }) {
            return new Promise((resolve) => {
                logout(state.token).then(() => {
                    commit('SET_TOKEN', '')
                    commit('SET_ROLES', [])
                    commit('SET_NAME', { name: '', welcome: welcome() })
                    storage.remove(ACCESS_TOKEN)
                    storage.remove(REFRESH_TIME)
                    resolve()
                }).catch((err) => {
                    console.log('logout fail:', err)
                        // resolve()
                }).finally(() => {})
            })
        }

    }
}

export default user