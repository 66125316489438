const getters = {

    token: state => state.user.token,
    avatar: state => state.user.avatar,
    nickname: state => state.user.name,
    welcome: state => state.user.welcome,
    roles: state => state.user.roles,
    userInfo: state => state.user.info,
    xeupoints: state => state.user.xeupoints,
    xeutotalpoints: state => state.user.xeutotalpoints,
    userGrade: state => state.user.userGrade,
    member: state => state.user.member,
    couponAmount: state => state.user.couponAmount,
    tplflagList: state => state.base.tplflagList,
    ossUrl: state => state.base.ossUrl,
}

export default getters