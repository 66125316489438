<template>
  <a-dropdown v-if="currentUser && currentUser.name" placement="bottomLeft" >
    <span class="ant-pro-account-avatar">
      <a-avatar
        size="large"
        :src="currentUser.avatar"
        class="antd-pro-global-header-index-avatar"
      />
      <!-- <span>{{ this.$store.getters.nickname }}</span> -->
    </span>
    <template v-slot:overlay>
      <a-menu class="ant-pro-drop-down menu" :selected-keys="[]">
        <a-menu-item v-if="menu" key="avatar">
          <!-- 用户信息区域 -->
     <div class="avatar_box">
           <a-avatar
        size="large"
        :src="currentUser.avatar"
        class="antd-pro-global-header-index-avatar"
      />
     <div class="avatar_name">
        <div>
          <span class="name">{{ currentUser.name}}</span>
          <div class="xegrade">{{currentUser.userGrade}}</div>
        </div>
        <span>{{currentUser.xeuCode}}</span>
     </div>
     </div>
        </a-menu-item>
           <!-- 分割线 -->
        <a-menu-divider v-if="menu" />
        <a-menu-item v-if="menu" key="center" @click="handleToMmmber">
           <i class="t-icon t-icon-VIP anticon"/>
          我的会员
        </a-menu-item>
        <a-menu-item v-if="menu" key="works" @click="handleToWorks">
         <i class="t-icon t-icon-zuopin anticon" />
          我的作品
        </a-menu-item>
        <a-menu-item v-if="menu" key="consumption" @click="handleToConsumption">
           <i class="t-icon t-icon-xiaofei anticon" />
          我的消费
        </a-menu-item>
        <a-menu-item v-if="menu" key="collection" @click="handleToCollection">
          <i class="t-icon t-icon-shoucang anticon" />
          我的收藏
        </a-menu-item>
        <a-menu-item v-if="menu" key="coupon" @click="handleToCoupon">
         <i class="t-icon t-icon-youhuiquan-copy anticon" />
          我的优惠券
        </a-menu-item>
        <!-- <a-menu-item v-if="menu" key="settings" @click="handleToSettings">
          <a-icon type="setting" />
          账号设置
        </a-menu-item> -->
        <!-- 分割线 -->
        <a-menu-divider v-if="menu" />
        <a-menu-item key="logout" @click="handleLogout">
          <a-icon type="logout" />
          退出登录
        </a-menu-item>
      </a-menu>
    </template>
  </a-dropdown>
  <span v-else>
    <a-spin size="small" :style="{ marginLeft: 8, marginRight: 8 }" />
  </span>
</template>

<script>
import { Modal } from "ant-design-vue";
// import { mapGetters } from 'vuex'
export default {
  name: "AvatarDropdown",
  data() {
    return {

    };
  },
  props: {
    currentUser: {
      type: Object,
      default: () => null,
    },
    menu: {
      type: Boolean,
      default: true,
    },
  },
  // computed: {
  //   ...mapGetters(['nickname', 'avatar','xeupoints','userGrade'])
  // },
  methods: {
    handleToMmmber() {
      this.$router.push({ path: "/manage/user" });
    },
    handleToWorks() {
      this.$router.push({ path: "/manage/works" });
    },
    handleToConsumption(){
      this.$router.push({ path: "/manage/consume" });
    },
    handleToCollection(){
      this.$router.push({ path: "/manage/collect" });
    },
    handleToCoupon(){
      this.$router.push({ path: "/manage/coupon" });
    },
    // handleToSettings() {
    //   this.$router.push({ path: "/manage/coupon" });
    // },
    handleLogout() {
      Modal.confirm({
        title: '信息',
        content: '您确定要注销吗？',
        onOk: () => {
          // return new Promise((resolve, reject) => {
          //   setTimeout(Math.random() > 0.5 ? resolve : reject, 1500)
          // }).catch(() => console.log('Oops errors!'))
          return this.$store.dispatch("Logout").then(() => {
            this.$router.push({ name: "login" });
          });
        },
        onCancel() {},
      });
    },
  },
};
</script>

<style lang="less" scoped>
.avatar_box{
  display: flex;
  align-items: center;
  .avatar_name{
    margin-left: 15px;
    span{
      font-size: 12px;
      color: #a5aec5;
    }
    .name{
  font-size: 14px;
  font-weight: 700;
  margin-bottom: 0px;
  line-height: 15px;
  color: #000;
}
  }
    .xegrade{
      display: inline-block;
            background: #f2cb51;
            margin: 0 6px;
            font-size: 12px;
            padding: 1px 8px;
            border-radius: 18px;
            color: #9a6e42;
        }
}
.ant-pro-drop-down {
  /deep/ .action {
    margin-right: 8px;
  }
  /deep/ .ant-dropdown-menu-item {
    min-width: 160px;
  }
}
</style>
