<template>
  <a-menu
    mode="inline"
    :default-selected-keys="['1']"
    :default-open-keys="['sub0', 'sub1']"
    :style="{ height: '100%', borderRight: 0 }"
    :selectedKeys="[$route.path]"
 
  >
  <a-sub-menu :key="'sub'+index" v-for="(item,index) in activeMenu" >
      <span slot="title" v-if="item.meta"><a-icon :type="item.meta.icon" />{{item.meta.title}}</span>
      <a-menu-item :key="menu.path" v-for="menu in item.children"> 
        
        <router-link :to="menu.path" v-if="item.meta && menu.component">
            <span>{{menu.meta.title}} </span>
        </router-link>
        <a :href="menu.path" v-if="item.meta && !menu.component" target="_target">
            <span>{{menu.meta.title}} </span>
        </a>
      </a-menu-item>

    </a-sub-menu>
    

    
  </a-menu>
</template>

<script>
export default {
  name: "SiderMenu",
  data() {
    return {};
  },
   computed: {
    activeMenu() {
       const routes = this.$router.options.routes.filter(item => item.path === '/manage' || item.path === '/basics')

    for(var i=0;i<routes.length;i++){
      for(var z=0;z<routes[i].children.length;z++){
      // console.log(routes[i].children[z].meta.hidden)
      if(routes[i].children[z].meta.hidden){
        // console.log(routes[i].children[z].meta.title)
        routes[i].children.splice(z,1)
        z--
      }
      
      }
    }
      return routes
    }
  },
  mounted(){
    
    // const routes = this.$router.options.routes.filter(item => item.path === '/manage' || item.path === '/basics')

    // for(var i=0;i<routes.length;i++){
    //   for(var z=0;z<routes[i].children.length;z++){
    //   // console.log(routes[i].children[z].meta.hidden)
    //   if(routes[i].children[z].meta.hidden){
    //     // console.log(routes[i].children[z].meta.title)
    //     routes[i].children.splice(z,1)
    //     z--
    //   }
      
    //   }
    // }
    // // const menus = (routes && routes.children) || []
    // console.log('routes',routes)
  },
  methods: {
    
    // selectMenuItem(obj,key) {
    //   this.$router.push({path: key})
    // },
  },
};
</script>
