<template>
  <a-config-provider :locale="antd">
  <div id="app">
    <router-view />
  </div>
  </a-config-provider>
</template>

<script>
import antd from 'ant-design-vue/es/locale-provider/zh_CN'
export default {
  name: "App",
  data() {
    return {
      antd
    }
  },
  mounted(){
    console.log(this.$route.path)
    if (this._isMobile()) {
      // 手机端
      this.$router.replace("/mobile");
    }
  },
  methods:{
     _isMobile() {
      let flag = navigator.userAgent.match(
     /(phone|pad|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows phone)/i
      );
      return flag;
    },
  },
  components: {

  },
};
</script>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  /* text-align: center; */
  color: #2c3e50;
  /* margin-top: 60px; */
}
.ellipsis {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
</style>
