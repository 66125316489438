/* eslint-disable no-unused-vars */
// 解析url参数并获取code
export function getUrlParam(name) { //name为要获取的参数名
    var reg = new RegExp("(^|&)" + name + "=([^&]*)(&|$)");
    var rrr = decodeURIComponent(window.location.search);
    var r = rrr.substr(1).match(reg);
    if (r != null) return unescape(r[2]);
    return null;
}
//函数作用：去除url中的code和state
export function delCodeandstate(to) {
    let path = ""
    for (let i in to.query) {
        if (i != "code" && i != "state") {
            path = path + "&" + i + "=" + to.query[i]
        }
    }
    path = path == "" ? "" : path.substring(1, path.length)
    path = path == "" ? "" : "/?" + path
    return path;
}

export function timeFix() {
    const time = new Date()
    const hour = time.getHours()
    return hour < 9 ? '早上好' : hour <= 11 ? '上午好' : hour <= 13 ? '中午好' : hour < 20 ? '下午好' : '晚上好'
}

export function welcome() {
    const arr = ['休息一会儿吧', '准备吃什么呢?', '要不要打一把 DOTA', '我猜你可能累了']
    const index = Math.floor(Math.random() * arr.length)
    return arr[index]
}


// export function handleScrollHeader(callback) {
//     let timer = 0
//     let beforeScrollTop = window.pageYOffset
//     callback = callback || function() {}
//     window.addEventListener(
//             'scroll',
//             event => {
//                 clearTimeout(timer)
//                 timer = setTimeout(() => {
//                     let direction = 'up'
//                     const afterScrollTop = window.pageYOffset
//                     const delta = afterScrollTop - beforeScrollTop
//                     if (delta === 0) {
//                         return false
//                     }
//                     direction = delta > 0 ? 'down' : 'up'
//                     callback(direction)
//                     beforeScrollTop = afterScrollTop
//                 }, 50)
//             },
//             false
//         ) ===
//         === =
//         let beforeScrollTop = window.pageYOffset
//     callback = callback || function() {}
//     window.addEventListener(
//             'scroll',
//             () => {
//                 clearTimeout(timer)
//                 timer = setTimeout(() => {
//                     let direction = 'up'
//                     const afterScrollTop = window.pageYOffset
//                     const delta = afterScrollTop - beforeScrollTop
//                     if (delta === 0) {
//                         return false
//                     }
//                     direction = delta > 0 ? 'down' : 'up'
//                     callback(direction)
//                     beforeScrollTop = afterScrollTop
//                 }, 50)
//             },
//             false
//         ) >>>
//         >>> > a014ced26f0ebb7a29fb6a15f4c8ac6d680be06d
// }

export function isIE() {
    const bw = window.navigator.userAgent
    const compare = (s) => bw.indexOf(s) >= 0
    const ie11 = (() => 'ActiveXObject' in window)()
    return compare('MSIE') || ie11
}
//判断是否是微信浏览器的函数
export function isWeiXin(){
    //window.navigator.userAgent属性包含了浏览器类型、版本、操作系统类型、浏览器引擎类型等信息，这个属性可以用来判断浏览器类型
    var ua = window.navigator.userAgent.toLowerCase();
    // alert(JSON.stringify(ua))
    //通过正则表达式匹配ua中是否含有MicroMessenger字符串
    if(ua.match(/MicroMessenger/i) == 'micromessenger'){
      return true;
    } else{
      return false;
    }
  }
/**
 * Remove loading animate
 * @param id parent element id or class
 * @param timeout
 */
export function removeLoadingAnimate(id = '', timeout = 1500) {
    if (id === '') {
        return
    }
    setTimeout(() => {
        document.body.removeChild(document.getElementById(id))
    }, timeout)
}
export function scorePassword(pass) {
    let score = 0
    if (!pass) {
        return score
    }
    // award every unique letter until 5 repetitions
    const letters = {}
    for (let i = 0; i < pass.length; i++) {
        letters[pass[i]] = (letters[pass[i]] || 0) + 1
        score += 5.0 / letters[pass[i]]
    }

    // bonus points for mixing it up
    const variations = {
        digits: /\d/.test(pass),
        lower: /[a-z]/.test(pass),
        upper: /[A-Z]/.test(pass),
        nonWords: /\W/.test(pass)
    }

    let variationCount = 0
    for (var check in variations) {
        variationCount += (variations[check] === true) ? 1 : 0
    }
    score += (variationCount - 1) * 10

    return parseInt(score)
}