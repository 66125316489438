<template>
  <a-layout-header class="header">
    <div class="logo" @click="gohome">
      <img :src="logo">
      </div>
    <right-content :top-menu="settings.layout === 'topmenu'" :is-mobile="isMobile" :theme="settings.theme" />
    <a-menu
      theme="light"
      mode="horizontal"
      :style="{ lineHeight: '64px',border:'none'}"
      @select="selectMenuItem"
      :selectedKeys="key"
    >
      <a-menu-item key="0"> 推荐</a-menu-item>
      <a-menu-item key="1"> 海报</a-menu-item>
      <a-menu-item key="2"> H5 </a-menu-item>
      <a-menu-item key="3"> 表单 </a-menu-item>
    </a-menu>

  </a-layout-header>
</template>
<script>
import RightContent from "./RightContent";
export default {
  name: "BasicLayout",
  components: {
    RightContent,
  },
  data() {
    return {
        logo:require("@/assets/logo.svg"),
        settings: {
        // 布局类型
        layout: 'topmenu', // 'sidemenu', 'topmenu'

        // 主题 'dark' | 'light'
        theme:'dark',

      },
      key:['0'],
      isMobile: false
    };
  },
  methods: {
    gohome(){
      this.$router.push({path: '/'})
    },
    selectMenuItem(obj) {
      // console.log(obj)
      if(obj.key==0){
        this.$router.push({path: '/'})
      }else{
        //从路由最后新增参数
         let  currentRoute = JSON.parse(JSON.stringify(this.$router.currentRoute.query)) // 这里我们需要的应该是值，因此必须转为深拷贝
         Object.assign(currentRoute, {prodid:obj.key})
        this.$router.push({path: '/store/search',query:currentRoute})
      }

    },
  },
  watch:{
    $route:{
      handler:function(val){
        if(val.query.prodid!=undefined){
          this.key = [val.query.prodid]
        }else if(val.name === 'home'){
           this.key = ['0']
        }
        else{
          this.key = []
        }
      },immediate:true,deep:true
    }
  }
};
</script>
<style scoped>
#components-layout-demo-top-side-2 .logo {
  width: 140px;
  height:49px;
  background: rgba(255, 255, 255, 0.2);
  margin: 0px 28px 16px 0;
  float: left;
  cursor: pointer;
}
#components-layout-demo-top-side-2 .logo img{
  width: 100%;
}
.ant-layout-header {
  height: 70px;
  line-height: 70px;
  background: #fff;
  box-shadow: 0px 3px 18px #f1f1f1;
  padding: 0 20px;
  z-index: 9;
  min-width: 1280px;
}
.ant-menu-dark,
.ant-menu-dark .ant-menu-sub {
  background: #fff;
}
.ant-layout {
  background: #fbfcff;
}
</style>