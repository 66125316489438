import router from './router'
import store from './store'
import storage from 'store'
import NProgress from 'nprogress' // progress bar
import '@/components/NProgress/nprogress.less' // progress bar custom style
import notification from 'ant-design-vue/es/notification'
import { setDocumentTitle, domTitle } from '@/utils/domUtil'
import { getUrlParam, delCodeandstate } from '@/utils/util'
import { ACCESS_TOKEN } from '@/store/mutation-types'
// import { i18nRender } from '@/locales'

NProgress.configure({ showSpinner: false }) // NProgress Configuration

const allowList = ['home', 'search', 'login', 'mobile', 'xuan1etch', 'useragreement', 'recruitment', 'about', 'H5', 'HB', 'Form'] // no redirect allowList
const loginRoutePath = '/user/login'
const defaultRoutePath = '/store/home'

router.beforeEach((to, from, next) => {


    let fullPath = to.fullPath
    console.log("fullpath", to, from)
    if (to.fullPath.includes("code")) { //判断url中是否有code，踩坑1-页面反复跳转
        fullPath = delCodeandstate(to)
    }
    console.log(fullPath)
    const code = getUrlParam('code'); //获取url 上面的code
    if (code) {
        // alert("s")
        store
            .dispatch('Login', { code: code })
            .then(res => {
                // next({ path: defaultRoutePath })
                next({ path: to.path + fullPath })
                console.log(res)

            })
    }
    console.log(code)
    NProgress.start() // start progress bar
    to.meta && typeof to.meta.title !== 'undefined' && setDocumentTitle(`${(to.meta.title)} - ${domTitle}`)
        /* has token */
    if (storage.get(ACCESS_TOKEN)) {
        if (to.path === loginRoutePath) {
            next({ path: defaultRoutePath })
            NProgress.done()
        } else {
            // check login user.roles is null
            console.log(store.getters.roles)
            if (store.getters.roles !== undefined && store.getters.roles.length === 0) {
                // request login userInfo
                store
                    .dispatch('GetInfo')
                    .then(res => {

                        console.log(res)

                        // 请求带有 redirect 重定向时，登录自动重定向到该地址
                        const redirect = decodeURIComponent(from.query.redirect || to.path)
                        if (to.path === redirect) {
                            console.log(to.path, redirect)
                                // 动态添加路由，防止路由还没加载完成
                                // next({ ...to, replace: true })
                            next()
                        } else {
                            // 跳转到目的路由
                            next({ path: redirect })
                        }

                    })
                    .catch(() => {
                        notification.error({
                                message: '错误',
                                description: '请求用户信息失败，请重试'
                            })
                            // 失败时，获取用户信息失败时，调用登出，来清空历史保留信息
                        store.dispatch('Logout').then(() => {
                            next({ path: loginRoutePath, query: { redirect: to.fullPath } })
                        })
                    })
            } else {
                next()
            }
        }
    } else {
        if (allowList.includes(to.name)) {
            // 在免登录名单，直接进入
            next()
        } else {
            // next()
            next({ path: loginRoutePath, query: { redirect: to.fullPath } })
            NProgress.done() // if current page is login will not trigger afterEach hook, so manually handle it
        }
    }
})

router.afterEach(() => {
    NProgress.done() // finish progress bar
})