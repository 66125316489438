import request from '@/utils/request'

const baseApi = {
    ossurl: '/api/getossurl',
    tplflag: '/api/tplflag',
    wxsign: '/api/getsign',
    checkrefreshtoken: '/api/auth/checkrefreshtoken'
}


export function getOssurl() {
    return request({
        url: baseApi.ossurl,
        method: 'get',
        // params: parameter
    })
}

export function getTplflag() {
    return request({
        url: baseApi.tplflag,
        method: 'get',

    })
}

export function getWxsign() {
    return request({
        url: baseApi.wxsign,
        method: 'get',

    })
}
export function checkrefreshtoken(parameter) {
    return request({
        url: baseApi.checkrefreshtoken,
        method: 'post',
        // params: parameter
        data: parameter
    })
}