import { getOssurl, getTplflag } from '@/api/base'


const base = {
    state: {
        ossUrl: '',
        tplflagList: []

    },

    mutations: {
        SET_OSSURL: (state, ossUrl) => {
            state.ossUrl = ossUrl
        },
        SET_TPLFLAGLIST: (state, tplflagList) => {
            state.tplflagList = tplflagList
        },

    },

    actions: {
        // 获取基础配置
        GetBase({ commit }) {
            return new Promise((resolve, reject) => {
                getOssurl().then(response => {
                    const result = response
                        // console.log(result)
                    commit('SET_OSSURL', result.data)
                    resolve(response)
                }).catch(error => {
                    reject(error)
                })
            })
        },
        GetTplFlag({ commit }) {
            return new Promise((resolve, reject) => {
                getTplflag().then(response => {
                    const result = response
                        // console.log(result)
                    commit('SET_TPLFLAGLIST', result.data)
                    resolve(response)
                }).catch(error => {
                    reject(error)
                })
            })
        },


    }
}

export default base