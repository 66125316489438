<template>
  <a-layout id="components-layout-demo-top-side-2" style=" overflow-x:auto;">
    <global-header style="position: fixed;width: 100%;z-index: 9;"></global-header>

    <a-layout>
      <global-sider style="position: fixed;top:70px;height:calc(100vh - 70px)">
          <template v-slot:menuRender>
            <category-menu></category-menu>
          </template>
      </global-sider>
      <a-layout style="padding: 120px 24px 24px 220px;overflow:auto">
        <!-- <a-breadcrumb style="margin: 16px 0">
          <a-breadcrumb-item>Home</a-breadcrumb-item>
          <a-breadcrumb-item>List</a-breadcrumb-item>
          <a-breadcrumb-item>App</a-breadcrumb-item>
        </a-breadcrumb> -->
        <a-layout-content
          :style="{ background: '#fbfcff', padding: '24px', margin: 0, minHeight: 'calc(100vh - ' + 100 + 'px)' }"
        >
          <!-- Content -->
          <router-view />

        </a-layout-content>
        <global-footer />
      </a-layout>
    </a-layout>
  </a-layout>
</template>
<script>
import GlobalHeader from '@/components/GlobalHeader'
import GlobalSider from '@/components/GlobalSider'
import GlobalFooter from '@/components/GlobalFooter'
import CategoryMenu from '@/components/CategoryMenu'
export default {
    name: 'BasicLayout',
    components: {
        GlobalFooter,
        GlobalSider,
        GlobalHeader,
        CategoryMenu
    },
    data() {
        return {
        collapsed: false,
        };
    },
};
</script>

<style lang="less" scoped>
  .ant-layout{
    background: #fbfcff;
  }
</style>