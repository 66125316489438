<template>
  <a-menu
    mode="inline"
    :selectedKeys="key"
     :open-keys.sync="openKeys"
    :style="{ height: '100%' }"
  >
    <a-sub-menu
      v-for="(category, index) in list"
      :key="'sub' + index"
      style="background-color: #fff"
    >
      <span slot="title">
        <!-- <a-icon type="user" /> -->
        {{ category[0].tftype }}
      </span>
      <a-menu-item
        v-for="(cate,cindex) in category"
        :key="cate.tfid"
        style="color: #a5aec5"
        @click="Press(cindex,index,cate.tfid,cate.tfname)"
      >
        <i class="disc" :class="activeid === cindex && categoryid === index?'active':''"></i>{{ cate.tfname }}
      </a-menu-item>
    </a-sub-menu>
       <a-button type="primary" size="large" class="recruiting">
         <a-icon type="phone" />
     设计师招募中
    </a-button>
  </a-menu>
</template>

<script>
import { getTplflag } from "@/api/manage";
export default {
  name: "CategoryMenu",
  data() {
    return {
      list: [],
      activeid:null,
      categoryid:null,
      openKeys: ['sub0'],
       key:[]
    };
  },
  mounted() {
    this.initializeCategory();
  },
  methods: {
    Press(index,cindex,tfid,tfname){
       this.activeid = index
       this.categoryid = cindex
       //从路由最后新增参数
        let  currentRoute = JSON.parse(JSON.stringify(this.$router.currentRoute.query)) // 这里我们需要的应该是值，因此必须转为深拷贝
         Object.assign(currentRoute, {tfid:tfid,tfname:tfname})
        this.$router.push({path: '/store/search',query:currentRoute})
    },
    initializeCategory() {
      getTplflag().then((res) => {
        // console.log(res)
        let flag = res.data;

        var result = [];
        new Promise((resolve) => {
          // eslint-disable-next-line no-mixed-spaces-and-tabs
          let newArr = this.screen(flag);
          resolve(newArr);
        }).then((newArr) => {
          console.log(newArr);
          for (let item of newArr) {
            let current = flag.filter((e) => {
              return item.tftype == e.tftype;
            });
            result.push(current);
          }
          console.log(result);
          this.list = result;
        });
      });
    },
    screen(arr) {
      let map = new Map();
      for (let item of arr) {
        if (!map.has(item.tftype)) {
          map.set(item.tftype, item);
        }
      }
      return [...map.values()];
    },
  },
  watch:{
    $route:{
      handler:function(val){
        if(val.query.tfid !=undefined){
          this.key = [parseInt(val.query.tfid)]
        }else{
          this.key = []
          this.activeid = null
        }
        console.log(this.key);
      },deep:true,immediate:true
    }
  }
};
</script>
<style lang="less" scoped>
.disc {
  display: inline-block;
  width: 3px;
  height: 3px;
  background-color: #a5aec5;
  margin-bottom: 3px;
  margin-right: 10px;
  border-radius: 4px;
}
.active{
  background-color:#0052ff ;
}
.recruiting{
  width: 150px;
margin-top: 32px;
margin-left: 18px;
font-size: 14px;
}
</style>

<style>
.ant-menu-submenu > .ant-menu {
  background-color: #fbfcff !important;
}
.ant-menu-submenu-selected{
  color: rgba(0, 0, 0, 0.65) !important;
}
 .ant-menu-item-active, .ant-menu:not(.ant-menu-inline) .ant-menu-submenu-open, .ant-menu-submenu-active, .ant-menu-submenu-title:hover{
   color: #a5aec5 !important;
}
.ant-menu-submenu-vertical > .ant-menu-submenu-title:hover .ant-menu-submenu-arrow::after, .ant-menu-submenu-vertical-left > .ant-menu-submenu-title:hover .ant-menu-submenu-arrow::after, .ant-menu-submenu-vertical-right > .ant-menu-submenu-title:hover .ant-menu-submenu-arrow::after, .ant-menu-submenu-inline > .ant-menu-submenu-title:hover .ant-menu-submenu-arrow::after, .ant-menu-submenu-vertical > .ant-menu-submenu-title:hover .ant-menu-submenu-arrow::before, .ant-menu-submenu-vertical-left > .ant-menu-submenu-title:hover .ant-menu-submenu-arrow::before, .ant-menu-submenu-vertical-right > .ant-menu-submenu-title:hover .ant-menu-submenu-arrow::before, .ant-menu-submenu-inline > .ant-menu-submenu-title:hover .ant-menu-submenu-arrow::before{
   background:linear-gradient(to right, rgba(0, 0, 0, 0.65), rgba(0, 0, 0, 0.65)) !important;
}
.ant-menu-item-selected{
color: #0052ff !important;
}
 .ant-menu-submenu-open, .ant-menu-submenu-active, .ant-menu-submenu-title:hover{
   color: rgba(0, 0, 0, 0.65) !important;
}
.ant-menu:not(.ant-menu-horizontal) .ant-menu-item-selected{
  background-color: none !important;
}
.ant-menu-vertical .ant-menu-item::after, .ant-menu-vertical-left .ant-menu-item::after, .ant-menu-vertical-right .ant-menu-item::after, .ant-menu-inline .ant-menu-item::after{
  border-right:3px solid transparent !important;
}
.ant-menu:not(.ant-menu-horizontal) .ant-menu-item-selected{
  background-color: transparent !important;
}
</style>
