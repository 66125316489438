import Vue from 'vue'
import Router from 'vue-router'
import { BasicLayout, AdminLayout, UserLayout, DetailsLayout, Xuan1etchLayout } from '@/layouts'
Vue.use(Router)
const RouteView = {
    name: 'RouteView',
    render: h => h('router-view')
}

let router = new Router({
    mode: 'history',
    // base: "/test",
    routes: [{
            path: '/',
            name: 'index',
            component: BasicLayout,
            redirect: '/store/home',
            children: [{
                    path: '/store',
                    name: 'store',
                    redirect: '/store/home',
                    component: RouteView,
                    meta: { title: '宣易商城', keepAlive: true, },
                    children: [{
                            path: '/store/home',
                            name: 'home',
                            component: () =>
                                import ('@/views/Home/Home'),
                            meta: { title: '宣易商城', keepAlive: true, }
                        },
                        {
                            path: '/store/search',
                            name: 'search',
                            // redirect: '/dashboard/workplace',
                            component: () =>
                                import ("@/views/search/search"),
                            meta: { title: '搜索', keepAlive: true, },
                        },

                    ]
                },


            ]
        },
        {
            path: '/details',
            name: 'details',
            component: DetailsLayout,
            redirect: '/details/productdetail',
            children: [{
                path: '/details/productdetail/:tcode',
                name: 'productdetail',
                component: () =>
                    import ("@/views/details/details"),
                meta: { title: '商品详情', keepAlive: true, },
            }]
        },
        {
            path: '/manage',
            name: 'manage',
            component: AdminLayout,
            redirect: '/manage/works',
            meta: { title: '我的', icon: 'user' },
            children: [{
                    path: '/manage/works',
                    name: 'works',
                    component: () =>
                        import ("@/views/works/works"),
                    meta: { title: '我的作品', hidden: false },

                },
                {
                    path: '/manage/user',
                    name: 'user',
                    component: () =>
                        import ("@/views/usercenter/usercenter"),
                    meta: { title: '我的会员', hidden: false },

                },
                {
                    path: '/manage/consume',
                    name: 'consume',
                    component: () =>
                        import ("@/views/consume/consume"),
                    meta: { title: '我的消费', hidden: false },

                },
                {
                    path: '/manage/collect',
                    name: 'collect',
                    component: () =>
                        import ("@/views/collect/collect"),
                    meta: { title: '我的收藏', hidden: false },

                },
                {
                    path: '/manage/coupon',
                    name: 'coupon',
                    component: () =>
                        import ("@/views/coupon/coupon"),
                    meta: { title: '我的优惠券', hidden: false },

                },
                {
                    path: '/manage/buycoupon',
                    name: 'buycoupon',
                    component: () =>
                        import ("@/views/buycoupon/buycoupon"),
                    meta: { title: '购买优惠券包', hidden: true },

                },
                {
                    path: '/manage/IntegralRecord',
                    name: 'IntegralRecord',
                    component: () =>
                        import ("@/views/IntegralRecord/IntegralRecord"),
                    meta: { title: '我的积分记录', hidden: true },

                },
                {
                    path: '/manage/worksdata/:workno',
                    name: 'worksdata',
                    component: () =>
                        import ("@/views/worksdata/worksdata"),
                    meta: { title: '作品数据', hidden: true },

                },
            ]
        },
        {
            path: '/xuan1etch',
            name: 'xuan1etch',
            component: Xuan1etchLayout,
            redirect: '/xuan1etch/about',
            meta: { title: 'XE', keepAlive: true },
            children: [{
                path: '/xuan1etch/useragreement',
                name: 'useragreement',
                component: () =>
                    import ("@/views/basics/agreement"),
                meta: { title: '用户协议', keepAlive: true },
            }, {
                path: '/xuan1etch/about',
                name: 'about',
                component: () =>
                    import ("@/views/xuan1etch/about"),
                meta: { title: '关于我们', keepAlive: true },
            }, {
                path: '/xuan1etch/recruitment',
                name: 'recruitment',
                component: () =>
                    import ("@/views/xuan1etch/recruitment"),
                meta: { title: '人才招聘', keepAlive: true },
            }, {
                path: '/xuan1etch/H5',
                name: 'H5',
                component: () =>
                    import ("@/views/xuan1etch/H5"),
                meta: { title: 'H5产品', keepAlive: true },
            }, {
                path: '/xuan1etch/HB',
                name: 'HB',
                component: () =>
                    import ("@/views/xuan1etch/HB"),
                meta: { title: '海报产品', keepAlive: true },
            }, {
                path: '/xuan1etch/form',
                name: 'Form',
                component: () =>
                    import ("@/views/xuan1etch/Form"),
                meta: { title: '表单产品', keepAlive: true },
            }]
        },
        {
            path: '/basics',
            name: 'basics',
            component: AdminLayout,
            redirect: '/basics/officialaccount',
            meta: { title: 'XE', icon: 'appstore' },
            children: [{
                    path: '/basics/officialaccount',
                    name: 'officialaccount',
                    component: () =>
                        import ("@/views/basics/officialAccount"),
                    meta: { title: '关注公众号', hidden: false },

                },
                // 外部链接
                {
                    path: 'https://support.qq.com/products/337514',
                    name: 'Monitor',
                    meta: { title: '意见反馈', hidden: false },
                },
                {
                    path: '/basics/agreement',
                    name: 'agreement',
                    component: () =>
                        import ("@/views/basics/agreement"),
                    meta: { title: '用户协议', hidden: false },

                },

            ]
        },
        {
            path: '/user',
            // name: 'user',
            component: UserLayout,
            redirect: '/user/login',
            children: [{
                    path: 'login',
                    name: 'login',
                    component: () =>
                        import ("@/views/user/Login")

                },

            ]
        },
        {
            path: '/mobile',
            name: 'mobile',
            component: () =>
                import ('@/views/mobile/mobile'),
            meta: { title: '宣易商城' },
        },
        {
            path: '/404',
            component: () =>
                import ('@/views/exception/404')
        },
        {
            path: '*',
            redirect: '/404',
            hidden: true
        }

    ]
})



// hack router push callback
const originalPush = Router.prototype.push
Router.prototype.push = function push(location, onResolve, onReject) {
        if (onResolve || onReject) return originalPush.call(this, location, onResolve, onReject)
        return originalPush.call(this, location).catch(err => err)
    }
    // if((navigator.userAgent.match(/(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i))) {

//     router.push({name:"mobile"})
//     console.log("m")

//     }else{

//     router.push({name:"index"})

//     }
export default router